import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout image="/img/transparent.png" title="404: NOT FOUND">
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
